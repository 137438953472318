






























import {StoreAccount} from "../../request/merchant/StoreAccount";

export default {
    data() {
        let self: any =this;
        return {
            modal: StoreAccount,
            formData: {

            },
            formRules: {
                name: {required: true, message: '请填写名称', trigger: 'blur'}
            },
            columns: [
                {
                    title: '',
                    key: '',
                    minWidth: 120
                }
            ]
        }
    },
    created(): void {
    },
    methods: {
        createNew() {
            let self: any = this;
            delete self.formData.id;
        },
        edit(row: any) {
            let self: any = this;
            self.formData = row;
        },
        submit() {
            let self: any = this;
            let params = {...self.formData};
            self.$refs['el-form'].validate((valid: boolean) => {
                if (valid) {
                    self.$refs['el-table'].doSubmit(params).then(() => {

                    });
                }
            })
        }
    }
}
